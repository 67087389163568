/*

EuclidCircularA

 */

@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Regular'), local('EuclidCircularA-Regular'), url('../fonts/EuclidCircularA-Regular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Regular Italic'), local('EuclidCircularA-RegularItalic'), url('../fonts/EuclidCircularA-RegularItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Bold'), local('EuclidCircularA-Bold'), url('../fonts/EuclidCircularA-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'EuclidCircularA';
  src: local('Euclid Circular A Bold Italic'), local('EuclidCircularA-BoldItalic'), url('../fonts/EuclidCircularA-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
