@charset "UTF-8";

@media #{$medium-only} {
  a {
    word-break: break-word;
  }

  ul {
    padding-left: $medium-side-padding;
  }

  div.container {
    padding: 0 $medium-side-padding;
  }

  header {
    #branding {
      #logo-left, #logo-right {
        padding: 0 $medium-side-padding;
      }
    }

    #breadcrumb {
      padding: 0 $medium-side-padding;
      min-height: rem-calc(45);
      line-height: rem-calc(45);
    }

    nav#navigation {
      ul.first-sub-nav {
        > li {
          > a {
            padding: rem-calc(13.5) $medium-side-padding;

            &:after {
              right: $medium-side-padding;
            }
          }

          > ul {
            padding-left: $medium-side-padding;
          }
        }
      }

      li, a {
        font-size: $font-size-mobile-regular;
        line-height: $line-height-mobile-regular;
        letter-spacing: $letter-spacing-mobile-regular;
      }
    }
  }


  footer {
    nav#meta_navigation, nav#lang_navigation {
      li, a {
        font-size: $font-size-mobile-regular;
        line-height: $line-height-mobile-regular;
        letter-spacing: $letter-spacing-mobile-regular;
      }
    }

  }
  #main-column > footer {
    &, #cookie_alert, #go_top {
      margin: 0 (-$medium-side-padding);
      padding-left: $medium-side-padding;
      padding-right: $medium-side-padding;
    }
  }


  // COMPONENTS

  // HOME COMPONENT
  .home-component {
    margin: 0 (-$medium-side-padding);

    .home-wrapper, .home-overlay, .home-second-wrapper {
      padding: 0 $medium-side-padding;
      min-height: calc(100vh - 78px);
    }
  }

  // EXPANDABLE TEXT & IMAGE
  .expandable-text-image-component, .simple-text-image-component {
    .text-image-wrapper {
      .text-image-content {
        @include flexBoxes(flex-end, flex-start, column, nowrap);

        .filtered-img-wrapper {
          max-width: calc(66% - 2px);

          img {
            max-width: 100%;
          }
        }

        img {
          max-width: calc(66% - 2px);
        }
      }
    }

    &.with-exp-content {
      .expandable-wrapper {
        .expandable-content {
          padding: rem-calc(23) $medium-side-padding;
        }
      }

      &.show-exp {
        .expandable-wrapper {
          margin: 0 (-$medium-side-padding);
        }
      }
    }
  }

}
