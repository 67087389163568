@charset "UTF-8";

@media #{$small-only} {

  #main-column {
    max-width: 100%;
  }

  a {
    word-break: break-word;
  }

  ul {
    padding-left: $small-side-padding;
  }

  div.container {
    padding: 0 $small-side-padding;
  }

  header {
    #branding {
      #logo-left, #logo-right {
        padding: 0 $small-side-padding;
        //height: rem-calc(30);
        max-width: 50%;
        box-sizing: border-box;

        a {
          img {
            //width: rem-calc(100);
            //height: rem-calc(30);
          }
        }
      }
    }

    #breadcrumb {
      padding: 0 $small-side-padding;
      min-height: rem-calc(27);
      line-height: rem-calc(27);
    }

    nav#navigation {
      ul.first-sub-nav {
        > li {
          > a {
            padding: rem-calc(13.5) $small-side-padding;

            &:after {
              right: $small-side-padding;
            }
          }

          > ul {
            padding-left: $small-side-padding;
          }
        }
      }

    }
  }

  #main-column > footer {
    //padding: 0 $small-side-padding;
    &, #cookie_alert, #go_top {
      //&{
      margin: 0 (-$small-side-padding);
      padding-left: $small-side-padding;
      padding-right: $small-side-padding;
    }
  }


  // COMPONENTS
  // HOME COMPONENT
  .home-component {
    margin: 0 (-$small-side-padding);

    .home-wrapper, .home-overlay, .home-second-wrapper {
      padding: 0 $small-side-padding;
    }

    .home-popup-wrapper.video-wrapper {
      width: 90% !important;
    }

    .home-second-wrapper {
      p, a {
        font-size: rem-calc(32);
        line-height: 1.05;
      }
    }
  }

  // EXPANDABLE TEXT & IMAGE
  .events-news-list-component {
    margin: 0 (-$small-side-padding);
  }
  .expandable-text-image-component, .simple-text-image-component {
    .text-image-wrapper {
      .text-image-content {
        @include flexBoxes(flex-end, flex-start, column, nowrap);

        img {
          margin: 0 (-$small-side-padding);
          max-width: 100vw;
        }
      }
    }

    &.with-exp-content {
      .expandable-wrapper {
        .expandable-content {
          padding: rem-calc(23) $small-side-padding;
        }
      }

      &.show-exp {
        .expandable-wrapper {
          margin: 0 (-$small-side-padding);
        }
      }
    }
  }
}
