$hamburger-menu-size: rem-calc(28);
$hamburger-menu-border-radius: .12em;
$hamburger-menu-line-height: 2px;
$hamburger-menu-space-between: rem-calc(8);
$hover-line-height: $hamburger-menu-line-height;
$anim-duration: .4s;

@media #{$xlarge-only} {
  header nav#navigation > ul > li > a {
    font-size: rem-calc(16);
  }
}

@media #{$xlarge-up} {
  #main-column {
    margin-left: rem-calc(-28);

    &.unique-column {
      margin-right: rem-calc(-28);
    }
  }

  .large-hide {
    display: none !important;
  }
}


.expandable-text-image-component, .simple-text-image-component {
  &.with-exp-content {
    &.show-exp {

      .open-mobile-detail {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        transition: visibility 0s $open-transition-duration, opacity $open-transition-duration linear;
      }

      .close-mobile-detail {
        visibility: visible;
        position: relative;
        opacity: 1;
        transition: visibility 0s $open-transition-duration, opacity $open-transition-duration linear;
      }
    }

    .close-mobile-detail, .open-mobile-detail {
      display: block !important;
      transition: opacity $open-transition-duration linear;
      padding: rem-calc(12) 0;
    }

    .open-mobile-detail {
      visibility: visible;
      opacity: 1;
    }

    .close-mobile-detail {
      visibility: hidden;
      position: absolute;
      opacity: 0;
      color: $white;
    }
  }
}


@import 'screens/large-down';
@import 'screens/medium-down';
@import 'screens/medium-only';
@import 'screens/small-only';






