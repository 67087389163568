@charset "UTF-8";

@media #{$medium-down} {

  header {
    a.icon-link {
      span.icon {
        width: rem-calc(36);
        padding-left: rem-calc(16);
      }
    }
  }
  footer {

    nav#lang_navigation {
      a {
        background: transparent;

        &:not(.active) {
          color: $darkgrey;
        }
      }
    }
  }

  // COMPONENTS
  // HOME COMPONENT
  .home-component {
    margin: 0 (-$medium-side-padding);

    .home-wrapper, .home-overlay, .home-second-wrapper {
      padding: 0 $medium-side-padding;
    }

    .home-popup-wrapper.video-wrapper {
      width: 45% !important;
    }

    .home-second-wrapper {
      padding-right: $medium-side-padding;

      p, a {
        font-size: $font-size-large;
      }
    }
  }


  // EXPANDABLE TEXT & IMAGE
  .expandable-text-image-component, .simple-text-image-component {
    .text-image-wrapper {
      .text-image-content {
        > div {
          flex: auto;
          width: 100%;
        }

        &.font-size-large {
          h2 {
            font-size: $font-size-large;
          }
        }
      }
    }
  }

  // Events & News
  .events-news-list-component {
    margin: 0 (-$medium-side-padding);

    .events-news-list-wrapper {
      .list-content {
        width: 100vw;
      }

      #events-news-title-wrapper {
        h2 {
          &.list-title {
            padding-right: $medium-side-padding;
          }
        }
      }

      .list-item {
        img {
          max-width: 100% !important;
        }
      }
    }

  }
}
