@charset "UTF-8";


.downloads.thumbnails {
  margin-top: rem-calc(10);
  li {
    position: relative;

    &.with-thumbnail  {
      margin-left: rem-calc(-20);
      margin-bottom: rem-calc(20);
      margin-top: rem-calc(20);
      min-height: rem-calc(50);
      @include flexBoxes(flex-start, center, row, nowrap);
      @media #{$small-only} {
        margin-left: rem-calc(0);
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(10);
        min-height: rem-calc(30);

        img {
          margin-right: rem-calc(10);
        }
      }


      img {
        width: 20%;
      }
      a {
        width: 79%;
        max-width: 79%;
        word-break: break-all;
      }
    }


    img {
      max-width: rem-calc(100);
      margin-right: rem-calc(20);
    }

    a {
      display: inline-block;
      padding: rem-calc(7);

      &:hover {
        white-space: normal;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
}

#main_wrapper.negative-color {
  &[style*="#000000"] {
    #main-column-wrapper {
      .downloads {
        span {color: $white}
        a:hover span {color: $black};
      }

      li::marker {
        color: $white;
      }

      .expandable-wrapper {
        li::marker {
          color: $black;
        }
      }

      .expandable-wrapper {
        .downloads{
          span {color: $black}
          a:hover span {color: $white};
        }
      }

      .expandable-link.open-mobile-detail {
        span {
          color: $white;
        }

        &:hover {
          span {
            color: $black;
          }
        }
      }
    }
  }
}


