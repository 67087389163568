@charset "UTF-8";

@media #{$large-down} {

  html:not(.fix-content) {
    #lang_navigation {
      display: none;
    }
  }

  .mgnlLikeBtn {
    display: none;
  }

  div.container {
    padding: 0 $large-side-padding;

  }

  .medium-show {
    display: block !important;
  }
  .medium-hide {
    display: none !important;
  }

  // HEADER
  header {
    #branding {
      @include flexBoxes(space-between, center, row, wrap);
      background: $grey;
      padding: 0;
      margin-top: rem-calc(48) !important;

      #logo-center {
        padding: rem-calc(0) 14vw;
        text-align: center;
        order: 1;
        flex: 1 1 100%;
        background: $white;
        box-sizing: border-box;

        a {
          height: rem-calc(48);
          box-sizing: border-box;
          padding: rem-calc(10) 0;
        }

        img {
          max-height: 100%;
        }
      }

      #logo-left, #logo-right {
        order: 2;
        padding: 0 $large-side-padding;

        a {
          height: rem-calc(40);
          display: block;
          box-sizing: border-box;
        }

        img {
          height: 100%;
        }
      }

      #logo-left {
        a {
          padding: rem-calc(9) 0 rem-calc(8) 0;
        }
      }

      #logo-right {
        a {
          padding: rem-calc(4) 0 rem-calc(3.2) 0;
        }
      }
    }

    #open_navigation.hamburger {
      position: fixed;

      &:before {
        position: fixed;
        content: '';
        top: 0;
        left: rem-calc(48);
        width: calc(100% - 48px);
        height: rem-calc(50);
        background-color: $white;
        pointer-events: none;
        display: none;
        z-index: -1;
      }

      & ~ #header-nav {
        & #navigation, & #lang_navigation {
          position: fixed;
          background-color: $white;
          z-index: 10;
        }
      }

      top: 0;
      left: 0;
      z-index: 10;
      background-color: $white;
      width: $hamburger-menu-size;
      height: rem-calc(24);
      padding: rem-calc(10) rem-calc(10) rem-calc(14) rem-calc(10);
      //border-radius: 0 $hamburger-menu-border-radius $hamburger-menu-border-radius 0;
      cursor: pointer;
      backface-visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        position: relative;
        flex: none;
        width: 100%;
        height: $hamburger-menu-line-height;
        background: $black;
        transition: all $anim-duration ease;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        &:before,
        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          top: -$hamburger-menu-space-between;
          left: 0;
          width: 100%;
          height: $hamburger-menu-line-height;
          background: inherit;
          transition: all $anim-duration ease;
        }

        &:after {
          top: $hamburger-menu-space-between;
        }
      }

      &.open {
        background: $black;
        transition: background .01s linear .5s;

        &:before {
          display: block;
        }

        > div {
          transform: rotate(135deg);
          background: $white;
          transition: background .01s linear .5s;

          &:before,
          &:after {
            top: 0;
            transform: rotate(90deg);
          }

          &:after {
            opacity: 0;
          }
        }
      }
    }

    #nav_home {
      width: 100%;
    }

    a.icon-link {
      span.icon {
        width: rem-calc(36);
        padding-left: rem-calc(38);
        padding-right: rem-calc(12);

        svg {
          margin-left: rem-calc(-10);
        }
      }
    }

    nav#navigation {
      position: absolute;
      //display: none;
      max-height: 0;
      overflow: hidden;
      top: rem-calc(48);
      width: 100%;
      z-index: 100;
      flex-direction: column;

      -webkit-transition: max-height $open-transition-duration ease-out;
      transition: max-height $open-transition-duration ease-out;


      ul.first-sub-nav {
        @include flexBoxes(space-between, stretch, column, nowrap);

        > li {
          min-height: rem-calc(54);
          padding: 0;

          > a {
            padding: rem-calc(13.5) $large-side-padding;
            display: block;
            border-bottom: solid 1px transparent;
            -webkit-transition: border-bottom $open-transition-duration*.5;
            transition: border-bottom $open-transition-duration*.5;


            &:after {
              content: "↓";
              width: rem-calc(15);
              height: rem-calc(30);
              position: absolute;
              right: $large-side-padding;
              -webkit-transition: transform $open-transition-duration*.5;
              transition: transform $open-transition-duration*.5;
            }
          }

          > ul.sub-nav {
            display: block;
            max-height: 0;
            overflow: hidden;
            -webkit-transition: padding-bottom 0s $open-transition-duration*.5, padding-top 0s $open-transition-duration*.5, max-height $open-transition-duration*.5 ease-out;
            transition: padding-bottom 0s $open-transition-duration*.5, padding-top 0s $open-transition-duration*.5, max-height $open-transition-duration*.5 ease-out;

            a {
              padding: rem-calc(2) 0;
              display: block;
            }
          }

          &.active {
            > a {
              border-bottom: solid 1px $black;

              &:after {
                transform: rotate(-180deg);
              }
            }

            &.negative-color {
              > a {
                border-bottom: solid 1px $white;
              }

              > ul.sub-nav {
                a.active {
                  &, mark, span {
                    color: $darkgrey;
                  }
                }
              }
            }

            > ul.sub-nav {
              max-height: rem-calc(3800);
              -webkit-transition: max-height $open-transition-duration*.5 ease-in;
              transition: max-height $open-transition-duration*.5 ease-in;
              visibility: visible;
              padding-top: rem-calc(12);
              padding-bottom: rem-calc(12);

              a {
                &.active {
                  &, mark, span {
                    color: $white;
                  }
                }
              }

              > li {
                &.active {
                  > ul.sub-nav {
                    max-height: rem-calc(3800);
                    visibility: visible;
                  }
                }
              }
            }
          }

          > ul {
            padding-left: $large-side-padding;
          }
        }
      }

      &.open {
        max-height: rem-calc(3800);
        transition: max-height $close-transition-duration ease-in;
        -webkit-transition: max-height $close-transition-duration ease-in;
      }
    }

    #lang_navigation {
      background: none;
      position: absolute;
      right: rem-calc(6);
      top: rem-calc(10)   ;
    }

    .icon-link {
      width: 100%;
      min-height: rem-calc(54);
      -webkit-justify-content: flex-start !important;
      justify-content: flex-start !important;

      svg {
        height: rem-calc(38) !important;
      }
    }

    #meta_navigation {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      align-items: flex-start;

      .icon-link {
        &:not(:last-child) {
          border-bottom: 1px solid $black;
        }
      }
    }

    #breadcrumb {
      padding: rem-calc(9) $large-side-padding;
    }
  }


  footer {
    padding-top: 0;

    #footer_text {
      padding: rem-calc(19) 0;
    }

    #footer_links {
      //@include flexBoxes(flex-start, flex-start, column, nowrap);
      ul {
        padding-left: 0;
      }

      #social_links_wrapper {
        order: 3;
      }
    }

    nav#meta_navigation, nav#lang_navigation {
      padding-bottom: rem-calc(24);

      li, a {
        font-size: $font-size-regular;
        line-height: $line-height-regular;
        letter-spacing: $letter-spacing-regular;
        padding: 0;
      }
    }

    #social_links_wrapper {
      padding-bottom: rem-calc(12);
      @include flexBoxes(flex-end, center, row-reverse, nowrap);

      #social_links {
        @include flexBoxes(flex-end, flex-start, row, nowrap);
        display: none;
      }

      &.open {
        #social_links {
          display: flex;
          padding-bottom: rem-calc(4);

          li {
            padding: 0 rem-calc(6);
          }

          a {
            color: $darkgrey;

            &:hover {
              color: $black;
            }
          }
        }
      }

      #footer_social_links_icon_wrapper {
        margin-right: rem-calc(11);

        svg {
          width: rem-calc(22);
          height: rem-calc(22);
        }
      }
    }


    margin-bottom: -40px !important;

    #go_top {
      border-bottom: 1px solid $white;
    }
  }

  #main-column-wrapper {
    min-height: calc(100vh - 88px);
  }

  #main-column > footer {
    &, #cookie_alert, #go_top {
      //&{
      margin: 0 (-$large-side-padding);
      padding-left: $large-side-padding;
      padding-right: $large-side-padding;
    }
  }

  #main_wrapper {
    padding-bottom: 0;
    .container {
      @include flexBoxes(flex-start, stretch, row, wrap);

      > div {
        flex: 1 1 100%;
      }
    }
  }


  // COMPONENTS

  // HOME COMPONENT
  .home-component {
    margin: 0 (-$large-side-padding);

    .home-wrapper, .home-overlay {
      min-height: calc(100vh - 78px);
      padding: 0 $large-side-padding;

      .home-popup-wrapper {
        left: 5%;
      }
    }

    .home-popup-wrapper.video-wrapper {
      width: 35% !important;
    }

    .home-second-wrapper {
      padding-right: $medium-side-padding;

      p, a {
        font-size: rem-calc(62);
      }
    }
  }

  // EXPANDABLE TEXT & IMAGE
  .expandable-text-image-component, .simple-text-image-component {
    box-sizing: border-box;
    padding-bottom: rem-calc(30);

    .text-image-wrapper {
      .text-image-content {
        @include flexBoxes(flex-end, flex-start, row, nowrap);
        margin: 0 rem-calc(-12);

        > div {
          flex: 1 1 50%;
          padding: 0 rem-calc(12);
          box-sizing: border-box;
        }

        .filtered-img-wrapper {
          display: block;
        }

        &.font-size-small {
          h2 {
            font-size: $font-size-small;
            line-height: $line-height-small;
            letter-spacing: $letter-spacing-small;
          }
        }

        &.font-size-normal {
          h2 {
            font-size: $font-size-regular;
            line-height: $line-height-regular;
            letter-spacing: $letter-spacing-regular;
          }
        }

        &.font-size-medium {
          h2 {
            font-size: $font-size-large;
            line-height: $line-height-large;
            letter-spacing: $letter-spacing-large;
          }
        }

        &.font-size-large {
          h2 {
            font-size: $font-size-big;
            line-height: $line-height-big;
            letter-spacing: $letter-spacing-big;
          }
        }
      }
    }

    &.with-exp-content {
      &.show-exp {
        .text-image-wrapper {
          h2 {
            mark {
              @include background-gradient();
            }
          }
        }

        .expandable-wrapper {
          max-height: rem-calc(3800);
          transition: max-height $close-transition-duration ease-in;
          -webkit-transition: max-height $close-transition-duration ease-in;
        }

        //.open-mobile-detail {
        //  visibility: hidden;
        //  opacity: 0;
        //  position: absolute;
        //  transition: visibility 0s $open-transition-duration, opacity $open-transition-duration linear;
        //}

        //.close-mobile-detail {
        //  visibility: visible;
        //  position: relative;
        //  opacity: 1;
        //  transition: visibility 0s $open-transition-duration, opacity $open-transition-duration linear;
        //}
      }

      //.close-mobile-detail, .open-mobile-detail {
      //  display: block !important;
      //  transition: opacity $open-transition-duration linear;
      //  padding: rem-calc(12) 0;
      //}
      //
      //.open-mobile-detail {
      //  visibility: visible;
      //  opacity: 1;
      //}
      //
      //.close-mobile-detail {
      //  visibility: hidden;
      //  position: absolute;
      //  opacity: 0;
      //  color: $white;
      //}

      .expandable-wrapper {
        max-height: 0;
        overflow: hidden;
        background: $white;
        margin: 0 (-$large-side-padding);

        -webkit-transition: margin 0s $close-transition-duration, max-height $open-transition-duration ease-out;
        transition: margin 0s $close-transition-duration, max-height $open-transition-duration ease-out;

        .expandable-content {
          padding: rem-calc(23) $large-side-padding;
        }
      }
    }
  }

  // EVENTS & NEWS LIST
  .events-news-list-component {
    margin: 0 (-$large-side-padding);

    .events-news-list-wrapper {
      #events-news-title-wrapper {
        -webkit-justify-content: flex-start;
        justify-content: flex-start;

        h2 {
          &.list-title {
            flex: 0 0 auto !important;
            padding-right: rem-calc(72);

            &.archiv-link {
              order: 1;
            }

            a {
              font-size: $font-size-regular;
              line-height: $line-height-regular;
              letter-spacing: $letter-spacing-regular;

              &:not(.active) {
                color: $white;
              }

              &.active {
                background: none;
              }
            }
          }
        }
      }

      #events_wrapper {
        .list-content.show-medium-hide {
          display: block !important;
        }

        .list-item {
          h3, p {
            font-size: $font-size-regular;
            line-height: $line-height-mobile-regular;
            letter-spacing: $letter-spacing-mobile-regular;
          }
        }

        .events-content {
          .list-item {
            img {
              max-width: rem-calc(376);
            }
          }
        }
      }
    }

    .list-wrapper {
      .list-content {
        .list-item {
          padding-bottom: rem-calc(21);

          .expandable-wrapper {
            margin-top: rem-calc(-16);

            .expandable-link-item {
              &.open, &.close {
                display: block !important;
                padding: rem-calc(8) 0;
                transition: opacity $open-transition-duration linear;
                font-size: $font-size-regular;
                line-height: $line-height-regular;
                letter-spacing: $letter-spacing-regular;
              }
            }

            &.open {
              .expandable-link-item {
                &.open {
                  visibility: hidden;
                  opacity: 0;
                  position: absolute;
                  transition: visibility 0s $open-transition-duration, opacity $open-transition-duration linear;
                }

                &.close {
                  visibility: visible;
                  position: relative;
                  opacity: 1;
                  transition: visibility 0s $open-transition-duration, opacity $open-transition-duration linear;
                }
              }

              .expandable-content {
                padding-top: rem-calc(16);
                max-height: rem-calc(3800);
                transition: max-height $open-transition-duration ease-in;
                -webkit-transition: max-height $open-transition-duration ease-in;
              }
            }

            &.close {
              .expandable-link-item {
                &.open {
                  visibility: visible;
                  opacity: 1;
                }

                &.close {
                  visibility: hidden;
                  position: absolute;
                  opacity: 0;
                }
              }

              .expandable-content {
                display: block;
                max-height: 0;
                overflow: hidden;
                transition: padding-top 0s $close-transition-duration, max-height $close-transition-duration ease-out;
                -webkit-transition: padding-top 0s $close-transition-duration, max-height $close-transition-duration ease-out;
              }
            }
          }
        }
      }
    }
  }
}
