$lessssbImage: "../images/less_ssb.png";

@import "fonts";
@import "settings";
@import "calendarwiz";
@import "downloads";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center, ul, li, ol,
dl, dt, dd,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

//ol, ul {
//  list-style: none;
//}
blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

mark {
  background: transparent;
}


html, body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: $white;
  max-width: $screen-max-width;
  width: 100%;
  margin: auto;
  padding: 0;
  color: $black;
  font-family: $font-family-euclidcirculara;
  font-weight: 500;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
  letter-spacing: $letter-spacing-regular;
  font-variant-numeric: tabular-nums;

  &:not(.no-breadcrumb) {
    #main_wrapper {
      padding-top: rem-calc(16);
    }
  }
}

h1 {
  //font-family: $font-family-euclidcirculara;
  font-size: $font-size-big;
  font-weight: 500;
  line-height: $line-height-big;
  letter-spacing: $letter-spacing-big;
}

h2, h3 {
  //font-family: $font-family-euclidcirculara;
  font-size: $font-size-large;
  font-weight: 500;
  line-height: $line-height-large;
  letter-spacing: $letter-spacing-large;
}

h4 {
  font-size: $font-size-medium;
  font-weight: 600;
  line-height: $line-height-small;
  letter-spacing: $letter-spacing-regular;
  margin-bottom: 12px;
}

a {
  //font-family: $font-family-euclidcirculara;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
  letter-spacing: $letter-spacing-regular;
  text-decoration: none;
  color: inherit;

  &:not(.color-hover) {
    &:hover, &.active {
      @include background-gradient();
      white-space: pre-wrap;
    }
  }

  &.color-hover {
    &:hover, &.active {
      color: $darkgrey;
    }
  }
}

p {
  font-weight: 500;
  font-size: $font-size-regular;
  line-height: $line-height-regular;
  letter-spacing: $letter-spacing-regular;

  padding-bottom: 16px;

  small {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;
  }
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

ul, ol {
  padding-left: rem-calc(32);
}

div.container {
  padding: 0 rem-calc(28);
  box-sizing: border-box;
}

.hide {
  display: none !important;
}

img {
  max-width: 100%;
}

#main_wrapper {
  position: relative;
  padding-bottom: rem-calc(40);

  .filtered-inner-wrapper {
    svg {
      max-width: 100%;
    }
  }

  .background-image-wrapper.no-filter {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;

    background-size: cover;
    background-position: center;
  }

  .background-filtered-image {
    .filtered-inner-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      overflow: hidden;

      .filtered_image {
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.negative-color {
    h1, h2, p, a, mark, span {
      color: $white;
    }

    a {
      &, mark {
        &:hover {
          color: $black;
        }
      }
    }

    .expandable-text-image-component, .simple-text-image-component {

      &.with-exp-content {
        a, mark {
          &:hover {
            color: $black;
          }
        }

        &.show-exp {
          .text-image-wrapper h2 mark {
            color: $black;
          }
        }
      }

      .expandable-wrapper {
        p, a, mark {
          color: $black;
        }

        a {
          &, mark {
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }

  .container {
    @include flexBoxes(flex-start, stretch, row, nowrap);
    //height: 100%;
    position: relative;

    > div {
      flex: 1 1 50%;
    }
  }

  #main-column {
    &.unique-column {
      height: calc(100% + 1px); //fix safari
    }

  }
}

header {
  #branding {
    padding: 0 rem-calc(30);
    @include flexBoxes(space-between, center, row, nowrap);

    #logo-center {
      a {
        display: block;
      }

      img {
        width: rem-calc(270);
      }
    }
  }

  #open_navigation {
    svg {
      width: rem-calc(25);
      height: rem-calc(25);
    }
  }

  #breadcrumb {
    padding: rem-calc(9) rem-calc(28);

    &.negative-color {
      &, p, a, mark, span {
        color: $white;
      }
    }
  }

  #header-nav {
    @include flexBoxes(flex-start, stretch, row, nowrap);
  }

  nav#navigation {
    flex-grow: 1;
    @include flexBoxes(flex-start, stretch, row, nowrap);

    a {
      background: none;
    }

    ul {
      margin: 0;
    }

    li {
      list-style: none;
    }

    ul.first-sub-nav {
      flex-grow: 1;
      padding-left: 0;

      ul.sub-nav {
        //display: none;
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
      }

      > li {
        > a {
          padding: 0 rem-calc(12);
          box-sizing: border-box;
        }

        &.negative-color {
          a {
            &, mark {
              color: $white;
            }
          }
        }
      }
    }
  }

  a.icon-link {
    position: relative;
    z-index: 2;

    &:hover {
      background: none !important;
      white-space: normal !important;
    }

    span.medium-show {
      display: none;
    }

    @include flexBoxes(center, center, row, nowrap);
  }

  ul.inline-list {
    height: 100%;
    padding: 0;
    @include flexBoxes(flex-start, center, row, nowrap);

    li {
      list-style: none;
    }
  }

  #nav_home, #meta_navigation {
    background-color: $grey;

    a {
      height: 100%;
      padding: 0 rem-calc(3);
      transition: background-color 200ms;

      &:hover {
        background-color: darkgrey !important;
      }

      svg {
        display: block;
        height: rem-calc(45);
        width: rem-calc(45);
      }
    }
  }

  #nav_home {
    width: rem-calc(55);
  }

  #meta_navigation {
    @include flexBoxes(flex-start, center, row, nowrap);
  }

  #lang_navigation {
    background-color: $grey;
    padding: rem-calc(0) rem-calc(16) rem-calc(0) rem-calc(8);

    ul {
      li {
        a {
          background: none;
          color: $darkgrey;
          padding: rem-calc(9) rem-calc(2);
          font-size: $font-size-medium;

          &:hover, &.active {
            color: $black;
          }
        }
      }
    }
  }
}

// FOOTER
footer {
  padding: rem-calc(72) rem-calc(24);
  background-color: $black;
  color: $white;

  //start footer test version
  //-webkit-flex-wrap: wrap !important;
  //flex-wrap: wrap !important;
  //
  //#footer_text_1, #footer_text_2, #footer_text_3{
  //  flex: 1 33%;
  //}
  //#footer_text_4{
  //  flex: 2 100%;
  //  text-align: center;
  //}
  //
  li {
    list-style: none;
  }

  //
  //#lang_navigation, #social_links{
  //  padding: 0 !important;
  //  ul a.active {
  //    color: $white !important;
  //  }
  //
  //  li{
  //    display: inline-block;
  //  }
  //}
  //end footer test version


  &, p, a {
    font-size: $font-size-small;
    line-height: $line-height-small;
    letter-spacing: $letter-spacing-small;
    padding-bottom: 0;
  }

  a:hover {
    color: $black;
  }

  #social_links {
    a:hover {
      background: none !important;
      white-space: normal !important;
    }
  }

  ul#social_links {
    @include flexBoxes(flex-start, center, row, nowrap);
    padding: rem-calc(32) 0 rem-calc(14) 0;

    li {
      list-style: none;
      padding: rem-calc(6);

      img {
        height: 28px;
        display: block;

        transition: all .4s;
        -moz-transition: all .4s;
        -webkit-transition: all .4s;
        -o-transition: all .4s;
        transform: scale(1);

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  #footer_text {
    padding: rem-calc(6) 0;
    border-bottom: 1px solid $white;

    p, a {
      font-size: $font-size-small !important;
    }
  }

  #footer_links {
    padding-top: rem-calc(32);
    padding-bottom: rem-calc(44);
    text-align: center;

    p, a {
      font-size: $font-size-small !important;
    }
  }

  #go_top {
    background: $black;
    color: $white;
    margin: 0 rem-calc(-24);
    padding: rem-calc(8) rem-calc(24);
    cursor: pointer;

    p {
      font-size: $font-size-mobile-regular;
      line-height: $line-height-mobile-regular;
      letter-spacing: $letter-spacing-mobile-regular;
      position: relative;

      span {
        position: absolute;
        right: 0;
      }
    }

  }

  #cookie_alert {
    background: $black;
    color: $white;
    margin: 0 !important;
    padding: rem-calc(16) rem-calc(24);

    position: fixed;
    width: 100%;
    max-width: $screen-max-width;
    bottom: 0;
    z-index: 300;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);

    #cookie_alert_content {
      @include flexBoxes(space-between, center, row, nowrap);
    }

    &.accepted {
      max-height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    .ca-accept {
      background: transparent;
      border: 0;
      background: none;
      box-shadow: none;
      border-radius: 0px;
      cursor: pointer;

      svg {
        width: rem-calc(20);
        height: rem-calc(20);
        padding-left: rem-calc(8);
      }

    }
  }
}

/* IE10+ CSS styles go here */
@media #{$xlarge-up} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .filtered-inner-wrapper {
    svg {
      min-height: rem-calc(350);
    }
  }
}


@media #{$xlarge-up} {

  body {
    @include flexBoxes(flex-start, center, column, nowrap);
    height: 100%;

    #main_wrapper {
      flex-grow: 1;
      overflow: hidden;
      width: 100%;
      padding-bottom: 0;

    }

    .container {
      height: calc(100vh - 172px); //safari fix
    }

    &.no-breadcrumb {
      .container {
        height: calc(100vh - 127px); //safari fix
      }
    }

    #main-column-wrapper {
      min-height: 100%;
    }

    &:not(.home-view) {
      #main-column-wrapper {
        padding-left: 28px;
      }
    }

    .events-news-list-component {
      margin: 0 -28px;
      width: calc(100% + 28px);

      .events-list-content {
        min-height: 100%;
      }
    }

    #second-column {
      padding-right: 28px;
      box-sizing: border-box;
    }

    #main-column {
      //@include flexBoxes(space-between, stretch, column, nowrap);
      overflow-x: visible;
      overflow-y: auto;


      .events-news-list-component {
        height: calc(100vh - 127px);

        .events-news-list-wrapper #events_wrapper {
          max-height: calc(100% - 50px);
        }
      }
    }
  }

  #main_wrapper {
    //flex: 1;
    //overflow: hidden;

    #main-column, #second-column {
      &:not(.unique-column) {
        overflow-y: auto;
        -ms-overflow-style: none; // IE 10+
        scrollbar-width: none; // Firefox
      }
    }
  }

  // HEADER
  header {
    background: $white;
    max-width: $screen-max-width;
    width: 100%;

    #branding {
      height: rem-calc(82);

      #logo-center a {
        padding: rem-calc(18) 0;
        box-sizing: content-box;

        img {
          height: rem-calc(46);
          //width: auto;
          display: block;
        }
      }

      #logo-left {
        img {
          height: rem-calc(36);
          width: rem-calc(235);
        }
      }

      #logo-right {
        img {
          height: rem-calc(45);
          width: rem-calc(215);
        }
      }
    }

    #open_navigation {
      display: none;
    }

    nav#navigation {
      position: relative;

      ul.first-sub-nav {
        > li {
          > ul.sub-nav {
            padding-left: rem-calc(12);

            > li {
              position: relative;
            }
          }

          &.first {
            > ul.sub-nav {
              padding-left: rem-calc(28);
            }
          }
        }
      }

      > ul {
        @include flexBoxes(space-between, center, row, nowrap);

        &:hover {
          > li:not(:hover) {
            background: $grey !important;
            color: $black !important;

            -webkit-transition: background .3s;
            transition: background .3s;

            &.negative-color {
              mark {
                color: $black !important;
              }
            }
          }
        }

        > li {
          flex: 1 1 100%;
          height: rem-calc(45);
          @include flexBoxes(flex-start, center, row, nowrap);

          -webkit-transition: background 0s;
          transition: background 0s;

          &.negative-color {
            a, mark {
              color: $white;
            }

            > ul.sub-nav {
              a:hover, a.active {
                span, mark {
                  color: $white;
                  @include background-gradient(rgba(255, 255, 255, 0), $grey);
                  white-space: pre-wrap;
                }
              }
            }
          }

          &:first-child {
            > a {
              padding-left: rem-calc(28);
            }

            &:hover {
              > a {
                margin: rem-calc(-8) 0 rem-calc(-12) rem-calc(-62);
                padding: rem-calc(8) 0 rem-calc(12) rem-calc(90);
              }
            }
          }

          &:hover {
            > a {
              margin: rem-calc(-8) 0 rem-calc(-12) rem-calc(-62);
              padding: rem-calc(8) 0 rem-calc(12) rem-calc(74);
              box-sizing: content-box;
            }
          }

          > a {
            padding: 0 rem-calc(16) 0 rem-calc(12);
            display: block;
            width: 100%;
          }


          > ul.sub-nav {
            max-height: 0;
            overflow: hidden;
            visibility: hidden;
            position: absolute;
            z-index: 100000;
            background: inherit;
            width: 100vw;
            top: rem-calc(45);
            left: 0;
            box-sizing: border-box;
            padding: rem-calc(9) rem-calc(24);
            //border-bottom: 4px solid $grey;

            -webkit-box-shadow: 0px 63px 70px 10px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 63px 70px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 63px 70px 10px rgba(0, 0, 0, 0.1);

            //-webkit-transition: visibility 0s $open-transition-duration*.5, max-height $open-transition-duration*.5 ease-out;
            //transition: visibility 0s $open-transition-duration*.5, max-height $open-transition-duration*.5 ease-out;

            ul.sub-nav {
              //display: none;
              max-height: 0;
              overflow: hidden;
              visibility: hidden;

              //-webkit-transition: visibility 0s $open-transition-duration, max-height $open-transition-duration ease-out;
              //transition: visibility 0s $open-transition-duration, max-height $open-transition-duration ease-out;
            }

            > li.active {
              > ul.sub-nav {
                //display: block;
                max-height: rem-calc(4000);
                visibility: visible;
                -webkit-transition: max-height $open-transition-duration ease-in;
                transition: max-height $open-transition-duration ease-in;
              }
            }

            ul {
              padding: 0 rem-calc(29);
            }

            a {
              line-height: 1.45;
            }

            a:hover, a.active {
              span, mark {
                @include background-gradient();
                white-space: pre-wrap;
              }
            }
          }

          &:hover {
            filter: none;

            > ul.sub-nav {
              min-height: rem-calc(396);
              max-height: rem-calc(4000);
              visibility: visible;

              -webkit-transition: max-height $open-transition-duration ease-in;
              transition: max-height $open-transition-duration ease-in;
            }
          }
        }
      }
    }
  }


  // CONTENT
  #main_wrapper {
    //flex: 1 1 100%;
    //@include flexBoxes(flex-start, stretch, row, nowrap);
    //> div{
    //  flex: 1 1 50%;
    //}

    #main-column {
      position: relative;
      //padding-right: rem-calc(24);
      //box-sizing: border-box;
    }

    #second-column {
      h1 {
        text-align: right;
        margin-top: rem-calc(-14); // the ¨ won't be visible on uppercase letters: Ö, Ü, Ï ...
        @media screen and (max-width: $xlarge-breakpoint) {
          font-size: vwRelative(110);
          margin-top: vwRelative(-14);
        }
      }
    }
  }

  // EXPANDABLE TEXT & IMAGE

  #main-column {
    &.show-exp {
      .expandable-text-image-component {
        &:not(.show-exp) {
          opacity: .4;

          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }
  .expandable-text-image-component, .simple-text-image-component {
    padding-bottom: $component-bottom-padding;

    .text-image-wrapper {
      .text-image-content {
        padding-right: rem-calc(28);
        box-sizing: border-box;

        .filtered-img-wrapper {
          max-width: 100%;

          img {
            max-width: 100%;
          }
        }

        h2 {
          margin-top: rem-calc(12);
          margin-bottom: rem-calc(12);
        }

        &.font-size-small {
          &, p, a {
            font-size: $font-size-small;
            line-height: $line-height-small;
            letter-spacing: $letter-spacing-small;
          }
        }

        &.font-size-normal {
          &, p, a {
            font-size: $font-size-regular;
            line-height: $line-height-regular;
            letter-spacing: $letter-spacing-regular;
          }
        }

        &.font-size-medium {
          &, p, a {
            font-size: $font-size-large;
            line-height: $line-height-large;
            letter-spacing: $letter-spacing-large;
          }
        }

        &.font-size-large {
          &, p, a {
            font-size: $font-size-big;
            line-height: $line-height-big;
            letter-spacing: $letter-spacing-big;
          }
        }
      }
    }

    &.with-exp-content {
      position: relative;

      .expandable-link {
        cursor: pointer;
        cursor: url('../images/more_ssb.png') 4 12, pointer;

        span {
          transition: background-color 200ms;
        }

        &:hover {
          > mark {
            @include background-gradient();
          }

          span {
            background-color: $white;
          }
        }
      }

      &.show-exp {
        .text-image-wrapper {
          h2 {
            mark {
              @include background-gradient();
            }
          }
        }

        .expandable-wrapper {
          display: block;
          min-height: rem-calc(300);

          a, mark {
            &:hover {
              color: $white;
              @include background-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            }
          }
        }

        .expandable-link {


          &:hover {
            cursor: url($lessssbImage) 4 12, pointer;
            cursor: pointer;

            span {
              background-color: transparent;
            }
          }
        }
      }

      .expandable-wrapper {
        display: none;
        position: fixed;
        z-index: 10;
        top: 100px;
        right: 0;
        width: 50%;
        background: $white;
        padding: rem-calc(8) rem-calc(13);
        overflow-y: auto;
        box-sizing: border-box;

        .expandable-content {
          padding-bottom: rem-calc(24);
        }

        &:hover {
          cursor: url($lessssbImage) 4 12, pointer;
          cursor: pointer;
        }
      }
    }
  }


  .events-news-list-component {
    height: 100%;

    .events-news-list-wrapper {
      height: 100%;

      #events_wrapper {
        height: 100%;
        flex: none;

        .list-content {
          overflow-y: auto;
          -ms-overflow-style: none; // IE 10+
          scrollbar-width: none; // Firefox
        }
      }
    }

    h3 {
      font-size: $font-size-xlarge;
      line-height: $line-height-xlarge;
    }

    h2, h2 > a {
      font-size: $font-size-medium !important;
      line-height: rem-calc(23) !important;
    }

    .list-wrapper .list-content {
      > div .list-item {
        padding-bottom: rem-calc(120) !important;
      }

      p, a {
        font-size: $font-size-medium !important;
        line-height: rem-calc(23) !important;
      }
    }
  }

  .expandable-text-image-component {
    padding-bottom: rem-calc(120) !important;
  }
}

// HOME COMPONENT
.home-component {
  position: relative;
  padding-bottom: rem-calc(26);

  .home-wrapper, .home-overlay, .home-second-wrapper {
    padding: 0 rem-calc(28);
    width: 100%;
    min-height: 100%;
    //min-height: calc(100vh - 127px);
    box-sizing: border-box;

    -webkit-transition: transform .8s ease-out .4s;
    transition: transform .8s ease-out .4s;
  }

  .home-wrapper {
    position: relative;
    background-attachment: fixed;
    background-position: center 60px;
    background-size: 110% auto;
    background-repeat: no-repeat;
    overflow: hidden;
    height: calc(100vh - 127px);

    .home-overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      background-repeat: no-repeat;
      background-size: 100% auto;
    }

    .home-background-video {
      video {
        position: absolute;
        left: 50%;
        top: 50%;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
      }

      #sound_button, #mute_button {
        position: absolute;
        width: 30px;
        height: 30px;
        background: #fff;
        border: none;
        border-radius: 100%;
        top: rem-calc(10);
        right: rem-calc(36);
        cursor: pointer;
        z-index: 1;

        svg {
          width: 120%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .home-popup-wrapper {
      max-width: rem-calc(345);
      position: absolute;
      left: 10%;
      bottom: 20%;
      box-sizing: border-box;
      z-index: 3;
      padding: rem-calc(80) rem-calc(40) rem-calc(11.5) rem-calc(12);
      background: $black;

      p, a {
        color: $white;
      }

      a:hover {
        color: $black;
      }
    }

    #home_go_next {
      position: absolute;
      bottom: rem-calc(60);
      left: 50%;
      transform: translateX(-50%);
      background: none;
      border: none;
      color: white;
      font-size: $font-size-large;
      cursor: pointer;
    }
  }

  .home-second-wrapper {
    background: $white;
    padding-right: 15%;
    padding-top: rem-calc(12);

    p, a {
      font-size: rem-calc(78);
      line-height: 0.96;
      letter-spacing: normal;
      display: inline-block;
      max-width: 100%;

      @media screen and (max-width: 430px) {
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
      }
      @media #{$xxxlarge-only} {
        font-size: rem-calc(100);
        line-height: rem-calc(110);
      }
      @media #{$xxlarge-only} {
        font-size: rem-calc(92);
        line-height: rem-calc(96);
      }
    }


    a.title-link {
      color: #2caf59;

      &:hover {
        color: $black;

        background: transparent;
        background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 12%, #2caf59 12%, #2caf59 88%, rgba(255, 255, 255, 0) 88%, rgba(255, 255, 255, 0) 100%);
        background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 12%, #2caf59 12%, #2caf59 88%, rgba(255, 255, 255, 0) 88%, rgba(255, 255, 255, 0) 100%);
        background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 12%, #2caf59 12%, #2caf59 88%, rgba(255, 255, 255, 0) 88%, rgba(255, 255, 255, 0) 100%);
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 12%, #2caf59 12%, #2caf59 88%, rgba(255, 255, 255, 0) 88%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
}

// EVENTS & NEWS LIST
.events-news-list-component {
  margin: 0 rem-calc(-24);
  //padding-bottom: rem-calc(28);
  .events-news-list-wrapper {
    //@include flexBoxes(space-between, stretch, column, nowrap);
    #events-news-title-wrapper {
      @include flexBoxes(space-between, stretch, row, nowrap);
      height: 51px;

      h2.archiv-link {
        flex: 1 1 calc(50% - 138px);
      }

      h2.news-link {
        flex: 1 1 50%;
      }
    }
  }

  .list-wrapper {
    @include flexBoxes(space-between, stretch, row, nowrap);

    .list-content {
      flex: 1 1 100%;

      > div .list-item {
        padding: 0 rem-calc(12);
        padding-bottom: rem-calc(48);
      }

      .list-item {
        position: relative;
        overflow: hidden;

        &.expandable-link, .expandable-link {
          cursor: default;

          &.with-exp-content {
            cursor: pointer;
          }
        }

        .expandable-link.expandable-link-item {
          cursor: pointer;
        }

        .expandable-wrapper {
          &.close {
            margin-top: rem-calc(-8);
            transition: margin 0s $close-transition-duration*.8;
            -webkit-transition: margin 0s $close-transition-duration*.8;

            .expandable-content {
              max-height: 0;
              overflow: hidden;

              -webkit-transition: max-height $open-transition-duration ease-out;
              transition: max-height $open-transition-duration ease-out;
            }

            .expandable-link-item.close {
              display: none;
            }
          }

          &.open {
            .expandable-link-item.open {
              display: none;
            }

            .expandable-content {
              max-height: rem-calc(3800);
              transition: margin 0s $close-transition-duration, max-height $close-transition-duration ease-in;
              -webkit-transition: margin 0s $close-transition-duration, max-height $close-transition-duration ease-in;
            }
          }

          .expandable-content {
            //padding-top: rem-calc(16);
          }

          .expandable-link-item {
            //font-size: $font-size-small;
            font-size: $font-size-medium;
            line-height: $line-height-small;
            letter-spacing: $letter-spacing-small;

            span {
              transition: background-color 200ms;
              padding: 0 rem-calc(3);
            }

            &:hover {
              span {
                background-color: $white;
              }
            }
          }
        }
      }

      p, a {
        font-weight: 500;
        font-size: $font-size-small;
        line-height: $line-height-small;
        letter-spacing: $letter-spacing-small;
      }


      &.events-content {
        img {
          max-width: rem-calc(214);
          margin: 0;
        }
      }

      &#archiv_list {
        h3, .expandable-link-item {
          font-size: $font-size-regular;
          line-height: $line-height-regular;
          letter-spacing: $letter-spacing-regular;
        }

        .expandable-wrapper {
          .expandable-link-item {
            text-align: right;
          }
        }
      }

      &#events_list, &#archiv_list {
        > div .list-item {
          padding-left: rem-calc(24);
        }
      }
    }


    &#events_wrapper {
      #events-title-wrapper {
        @include flexBoxes(flex-start, flex-start, row, nowrap);

        a.active {
          background: $white;
        }
      }

      .list-content {
        &.close {
          display: none;
        }

        &.archive-content {
        }
      }
    }

    &:hover {
      .list-content:not(:hover) {
        background-color: $white;
      }
    }
  }

  img {
    display: block;
    //filter: grayscale(100%) contrast(1.1);
    //margin-bottom: rem-calc(10);
  }

  h3 {
    margin: 0;
    margin-bottom: rem-calc(16);
    padding-right: rem-calc(21);

    mark {
      @include background-gradient();
    }
  }

  h2 {
    padding: rem-calc(14) rem-calc(24);
    margin: 0;

    &, & > a {
      font-weight: 500;
      font-size: $font-size-small;
      line-height: $line-height-small;
      letter-spacing: $letter-spacing-small;
    }
  }

  #events_wrapper, #news-wrapper {
    flex: 1 1 100%;
  }

  .news-wrapper {
    .list-item:not(:first-child) {
      border-top: 1px solid $black;
      padding-top: rem-calc(12);
    }
  }
}

// Vimeo & Youtube
.vimeoEmbed-wrapper, .youtubeEmbed-wrapper {
  margin-bottom: $component-bottom-padding;
  .vimeoEmbed, .youtubeEmbed, .lazy-video {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    background-color: transparentize(#eeeeee, 0.7);
    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.video-wrapper {
  position: relative;
  margin-bottom: $component-bottom-padding;

  .sizer {
    position: relative;
    padding-bottom: 56.25%;
  }
  .wrapper{
    position: relative;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  &.poster {
    .sizer {
      opacity: 0;
    }
    &.video-init {
      .sizer {
        opacity: 1;
      }
    }

    &.video-init{
      .video-poster {
        transform: scale(0);
        opacity: 0;
      }
    }
    .video-poster {
      position: absolute;
      @include flexBoxes(center, center, row, nowrap);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover;
      background-position: center;
      transition: transform 200ms cubic-bezier(0.17, 0.67, 0.58, 0.72), opacity 200ms cubic-bezier(0.17, 0.67, 0.58, 0.72);

      button {
        border: none;
        padding: 0;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
      }
    }
  }
}

@import "screens";
