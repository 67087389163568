@import "functions";

$white: #ffffff;
$black: #000000;
$grey: #ECECEC;
$darkgrey: #9b9b9b;

$font-family-euclidcirculara: Euclid Circular A, EuclidCircularA, Montserrat, Verdana;

$font-size-big: rem-calc(110);
$font-size-xlarge: rem-calc(54);
$font-size-large: rem-calc(45);
$font-size-regular: rem-calc(21);
$font-size-medium: rem-calc(18);
$font-size-small: rem-calc(14);
$font-size-mobile-regular: rem-calc(23);

$line-height-big: 1.05;
$line-height-xlarge: 1.07;
$line-height-large: 1.04;
$line-height-regular: 1.29;
$line-height-small: 1.21;
$line-height-mobile-regular: 1.43;

$letter-spacing-big: -3.5px;
$letter-spacing-large: -1px;
$letter-spacing-regular: -0.47px;
$letter-spacing-small: -0.31px;
$letter-spacing-mobile-regular: -0.6px;

//width: 1440px;
//$screen-max-width: rem-calc(1680);
$screen-max-width: none;
$component-bottom-padding: rem-calc(49);

$open-transition-duration: 0.45s;
$close-transition-duration: 0.55s;

$small-side-padding: rem-calc(22);
$medium-side-padding: rem-calc(24);
$large-side-padding: rem-calc(44);




// Media Query Ranges
$small-breakpoint:  em-calc(640)  !default;
$medium-breakpoint: em-calc(768) !default;
$large-breakpoint:  em-calc(1024) !default;
$xlarge-breakpoint: em-calc(1440) !default;
$xxlarge-breakpoint: em-calc(1920) !default;
$xxxlarge-breakpoint: em-calc(2560) !default;

$small-range:   (0, $small-breakpoint) !default;
$medium-range:  ($small-breakpoint  + em-calc(1), $medium-breakpoint) !default;
$large-range:   ($medium-breakpoint + em-calc(1), $large-breakpoint)  !default;
$xlarge-range:  ($large-breakpoint  + em-calc(1), $xlarge-breakpoint) !default;
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), $xxlarge-breakpoint) !default;
$xxxlarge-range: ($xxlarge-breakpoint + em-calc(1), em-calc(99999999)) !default;

$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small-up: $screen !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
$medium-down: "#{$screen} and (max-width:#{upper-bound($medium-range)})";

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
$large-down: "#{$screen} and (max-width:#{upper-bound($large-range)})";

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

$xxxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxxlarge-range)})" !default;
$xxxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxxlarge-range)}) and (max-width:#{upper-bound($xxxlarge-range)})" !default;
