

.calendar-component{

  padding-right: 28px;

  @media #{$large-down} {
    padding-right: 0;
  }


  height: 85vh;
  #calendarwiz-integration {
    height: 100%;

    .logout {
      position: absolute;
      right: 10px;
    }

    iframe {
      padding-top: 30px;
    }
  }
}

#calendarwiz-login {
  input[type=text], input[type=password], select, textarea{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }

  .pwd-hint {
    display: none;
    color: red;

    &.show {
      display: initial;
    }
  }

  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }

  input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    margin-top: 10px;
  }

  &.container2 {
    border-radius: 5px;
    padding: 20px 0;
    width: 50%;
  }

  .col-25 {
    float: left;
    width: 35%;
  }

  .col-75 {
    float: left;
    width: 65%;
    margin-top: 12px;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }

  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }

  .tab button:hover {
    background-color: #ddd;
  }

  .tab button.active {
    background-color: #ccc;
  }

  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }

  .tabcontent {
    animation: fadeEffect 1s;
  }

  @keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @media only screen and (max-width: 64em) {
    &.container2 {
      width: 80%;
    }
    .col-25, .col-75{
      width: 100%;
      margin-top: 0;
    }
    input[type=submit] {
      width: 100%;
      margin-top: 10px;
    }

    .tablinks {
      width: 100%;
      text-align: left;
    }
  }
}

