// This is the default html and body font-size for the base rem value.
$rem-base: 16px !default;

// IMPORT ONCE
// We use this to prevent styles from being loaded multiple times for components that rely on other components.
$modules: () !default;

@mixin exports($name) {
  // Import from global scope
  $modules: $modules !global;
  // Check if a module is already on the list
  $module_index: index($modules, $name);
  @if (($module_index == null) or ($module_index == false)) {
    $modules: append($modules, $name) !global;
    @content;
  }
}

@mixin background-gradient($transparentColor:rgba(255, 255, 255, 0), $color:rgba(255, 255, 255, 1)) {
  background: transparent;
  background-image: -webkit-linear-gradient(top, $transparentColor 0%, $transparentColor 12%, $color 12%, $color 88%, $transparentColor 88%, $transparentColor 100%);
  background-image: -moz-linear-gradient(top, $transparentColor 0%, $transparentColor 12%, $color 12%, $color 88%, $transparentColor 88%, $transparentColor 100%);
  background-image: -o-linear-gradient(top, $transparentColor 0%, $transparentColor 12%, $color 12%, $color 88%, $transparentColor 88%, $transparentColor 100%);
  background-image: linear-gradient(to bottom, $transparentColor 0%, $transparentColor 12%, $color 12%, $color 88%, $transparentColor 88%, $transparentColor 100%);
}

@mixin flexBoxes($justify:center, $align:center, $direction:row, $wrap:nowrap) {
  display: -moz-box;              /* OLD - Firefox 19- (doesn't work very well) */
  display: -ms-flexbox;           /* TWEENER - IE 10 */
  display: -webkit-flex;          /* NEW - Chrome */
  display: flex;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */

  -ms-flex-direction: $direction;
  -webkit-flex-direction: $direction;
  flex-direction: $direction;

  -ms-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;

  -webkit-align-items: $align;
  align-items: $align;

  -webkit-justify-content: $justify;
  justify-content: $justify;
}

//
// @functions
//

// GRID
@function columns($columns, $container-columns: $maximum-columns) {
  @return percentage((1 / $container-columns) * $columns);
}

@function columnWidth($columns, $container-columns: $maximum-columns) {
  @return ((100 / $container-columns) * $columns) + vw;
}
@function columnMaxWidth($columns, $container-columns: $maximum-columns) {
  @return (($max-width / $container-columns) * $columns) + px;
}


// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// TEXT INPUT TYPES

@function text-inputs( $types: all, $selector: input ) {

  $return: ();

  $all-text-input-types:
    text
    password
    date
    datetime
    datetime-local
    month
    week
    email
    number
    search
    tel
    time
    url
    color
    textarea;

  @if $types == all { $types: $all-text-input-types; }

  @each $type in $types {
    @if $type == textarea {
      @if $selector == input {
        $return: append($return, unquote('#{$type}'), comma)
      } @else {
        $return: append($return, unquote('#{$type}#{$selector}'), comma)
      }
    } @else {
      $return: append($return, unquote('#{$selector}[type="#{$type}"]'), comma)
    }
  }

    @return $return;

}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function data($attr) {
  @if $namespace {
    @return '[data-' + $namespace + '-' + $attr + ']';
  }

  @return '[data-' + $attr + ']';
}

// REM CALC

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of rem's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported:
//
//   rem-calc(10 20 30px 40);
//
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
//
//   rem-calc((10, 20, 30, 40px));
//
// Optionally call with a different base (eg: 8px) to calculate rem.
//
//   rem-calc(16px 32px 48px, 8px);
//
// If you require to comma separate your list
//
//   rem-calc((16px, 32px, 48), 8px);

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}


@function em-calc($values, $base-value: $rem-base) {
  $remValues: rem-calc($values, $base-value: $rem-base);

  $max: length($remValues);

  @if $max == 1 { @return strip-unit(nth($remValues, 1)) * 1em; }

  $emValues: ();
  @for $i from 1 through $max {
    $emValues: append($emValues, strip-unit(nth($remValues, $i)) * 1em);
  }
  @return $emValues;
}


// Deprecated: OLD EM CALC
@function emCalc($values) {
  @return em-calc($values);
}

@function vwRelative($size, $base:1440) {
  @if $base == 0 {
    $base: 1440;
  }

  @return #{(round(($size / $base) * 10000) / 100) + 'vw'}
}
